import { GetCall, PostCall, PutCall } from './_serviceHelperCor'

const serviceUrl = '/categoriasProductos';

export const listarCategoriasProducto = (filters) => {
    var params = [
        { 'key': 'nombre', 'val': filters?.nombre },
        { 'key': 'estadoActivo', 'val': filters?.estadoActivo }
    ];
    return GetCall(serviceUrl, params)
}

export const agregarCategoriaProducto = (producto) => {
    return PostCall(serviceUrl, producto).then(d => { return { errores: d.listaErrores, entidad: d.objetoOperado } })
}

export const modificarCategoriaProducto = (producto) => {
    return PutCall(serviceUrl, producto).then(d => { return { errores: d.listaErrores, entidad: d.objetoOperado } })
}

export const borrarCategoriasProducto = (listaIds) => {
    return PutCall(serviceUrl + '/borrar', listaIds).then(d => { return { errores: d.listaErrores, entidad: d.objetoOperado } })
}

export const reactivarCategoriasProducto = (listaIds) => {
    return PutCall(serviceUrl + '/reactivar', listaIds).then(d => { return { errores: d.listaErrores, entidad: d.objetoOperado } })
}