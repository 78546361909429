import { GetCall, PostCall, PutCall } from './_serviceHelperCor'

export const listarProducto = (filters, responseStatus) => {
    var params = [
        {'key': 'codigo', 'val': filters.codigo},
        {'key': 'descripcion', 'val': filters.descripcion},
        {'key': 'categoriaId', 'val': filters.categoriaId},
        {'key': 'estadoActivo', 'val': filters.estadoActivo}
    ];
    return GetCall('/productos', params, responseStatus).then(data => {return {datos: data}})
};

export const agregarProducto = (producto) => {
    return PostCall('/productos', producto)
};

export const editarProducto = (producto) => {
    return PutCall('/productos', producto)
};

export const borrarProductos = (listaIds, responseStatus) => {
    return PutCall('/productos/borrar', listaIds, responseStatus);
};

export const reactivarProductos = (listaIds, responseStatus) => {
    return PutCall('/productos/reactivar', listaIds, responseStatus);
};

export const autocompProducto = (texto, responseStatus) => {
    return GetCall('/productos/autocompletar', [{'key': 'texto', 'val': texto}], responseStatus);
};

export const buscarSiguienteCodigoDisponible = (responseStatus) => {
    return GetCall('/productos/codigoLibre', [], responseStatus);
}