import { GetCall } from './_serviceHelperApi'

const serviceUrl = '/productos'

export const reportePoductosValorYStockPorCategoria = () => {
    var params = []
    return GetCall(serviceUrl+'/reportes/productosValorYStockPorCategoria', params)
}

export const reportePreciosObsoletos = () => {
    var params = []
    return GetCall(serviceUrl+'/reportes/preciosObsoletos', params)
}