import React from 'react'
import { DataTable } from '../../../comp/DataTable'
import { Traduccion } from '../../../comp/Traduccion'
import {
    listarCategoriasProducto,
    agregarCategoriaProducto,
    modificarCategoriaProducto,
    borrarCategoriasProducto,
    reactivarCategoriasProducto
} from '../../../services/servCategoriasProductos'
import { ModalDelete } from '../../../comp/ModalDelete'
import { Acciones } from '../../../utils/Acciones'
import { Tipos } from '../../../utils/Tipos'
import { Detalle } from './contenido'

export const CategoriasProductos = () => {

    const tableConfig = {
        title: 'ViewCategoriasProductos',
        headers: ['TXT_NOMBRE'],
        types: [Tipos().DESC],
        access: ['nombre'],
        rowId: 'id'
    }

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const handleDetailOpen = (action, selection) => {
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        switch (detailAction) {
            case Acciones().ADD:
                agregarCategoriaProducto(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    obj.id = data;
                    obj.activo = true;
                    tableData.unshift(obj)
                    setTableSelection([])
                    setIsDetailOpened(false)
                })
                break
            case Acciones().EDIT:
                obj.id = tableSelection[0].id
                modificarCategoriaProducto(obj).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection[0].nombre = obj.nombre
                    setIsDetailOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().REAC:
                reactivarCategoriasProducto(tableSelection.map(e => e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = true })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().DEL:
                borrarCategoriasProducto(tableSelection.map(e => e.id)).then(data => {
                    if (data.errores) {
                        alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + data.errores.map(d => Traduccion(d.codigo)).join('\r* '))
                        return
                    }
                    tableSelection.forEach(e => { e.activo = false })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            default: return
        }
    }

    const formatDataForTable = (datos) => {
        datos.find(e => e.id === 1).editable = false
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarCategoriasProducto()
            .then(data => {
                formatDataForTable(data)
                setTableData(data)
                setIsTableLoading(false)
            })
    }, [])

    return <div>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tableData}
            isLoading={isTableLoading}
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
    </div>
}