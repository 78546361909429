import React, { Component } from 'react'
import {
    Card, CardContent, CardHeader, Grid
} from '@mui/material'
import { DataTable } from '../../comp/DataTableSimple'
import { Traduccion } from '../../comp/Traduccion'
import { Tipos } from '../../utils/Tipos'

import { HabilitarAfipCard } from './HabilitarAfip'

import { getModules } from '../../services/tenant'

const FncMiEmpresa = () => {

    const [modulos, setModulos] = React.useState([])

    const reloadAfipEnabledStatus = () => {
        let aux = modulos.map(e => e)
        aux.find(e => e.id === 3).activoEnTenant = true
        setModulos(aux)
    }

    React.useEffect(() => {
        getModules().then(d => {
            d.modulos.forEach(e => {
                e.nombreTraducido = Traduccion(e.nombre)
            });
            setModulos(d.modulos)
        })
    }, [])

    return <div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Card>
                    <CardHeader title={Traduccion('TXT_MIS_SERVICIOS')} />
                    <CardContent>
                        <DataTable headers={['TXT_NOMBRE']}
                            types={[Tipos().DESC]}
                            access={['nombreTraducido']}
                            data={modulos.filter(e => e.activoEnTenant)}
                            isLoading={false}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid container item xs={12} md={6} lg={8}>
                <Grid item xs={6} sm={4} md={6} lg={3} xl={2}>
                    {
                        modulos
                            .filter(e => e.id === 3)
                            .map((e, idx) =>
                                <HabilitarAfipCard
                                    precio={e.precio}
                                    activado={e.activoEnTenant}
                                    onFinishCallback={reloadAfipEnabledStatus}
                                />
                            )
                    }

                </Grid>
            </Grid>
        </Grid>
    </div>
}

export class MiEmpresa extends Component {

    render() {
        return <FncMiEmpresa />;
    }

}