import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import { Tenant } from '../../config/Sesion'
import { Traduccion } from '../../comp/Traduccion'
import { FormatFechaHora, FormatImporte, FormatCantidad } from '../../utils/Formatter'

export const TicketToPrint = ({nroTicket, fechaHora = new Date().toISOString(), detallesProductos, totalVendido = 0, totalCobrado = 0, vuelto = 0}) => {

    return (
            <div id="ticket" style={{margin: '1em'}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}><Typography color="inherit" style={{flex: 1, textAlign: 'center'}}><b>{Tenant().nombre.toUpperCase()}</b></Typography></Grid>
                    <Grid item xs={12}>{Tenant().direccion}</Grid>
                    <Grid item xs={12}>{Tenant().localidad + ' - ' + Tenant().provincia}</Grid>
                    <Grid item xs={12}>{Tenant().razonSocial}</Grid>
                    <Grid item xs={12}>{Tenant().cuit}</Grid>
                    <Grid item xs={12}>{Tenant().condicionIva}</Grid>
                    <Grid item xs={12}>{Traduccion('TXT_FECHA_INICIO_DE_ACT') + ' ' + Tenant().fechaInicioActividades}</Grid>
                    <br/><br/>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>{Traduccion('TXT_TICKET_NRO')}</Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>{nroTicket}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>{Traduccion('TXT_FECHA') + ': '}</Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>{FormatFechaHora(fechaHora)}</Grid>
                        </Grid>
                    </Grid>
                    <Divider width="90%" style={{margin: '0.5em auto'}}/>
                    { detallesProductos.map((det, idx) =>
                        <Grid item xs={12} key={idx}>
                            <Grid container spacing={0}>
                                <Grid item xs={9}>{FormatCantidad(det.cantidad)} x {FormatImporte(det.precio)}<br/>{det.producto.descripcion}</Grid>
                                <Grid item xs={3} style={{textAlign: 'right'}}><br/>{FormatImporte(det.cantidad * det.precio)}</Grid>
                            </Grid>
                        </Grid>
                        )}
                    <Divider width="90%" style={{margin: '0.5em auto'}}/>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>{Traduccion('TXT_TOTAL').toUpperCase()}</Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>{FormatImporte(totalVendido)}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>{Traduccion('TXT_SU_PAGO')}</Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>{FormatImporte(totalCobrado)}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={9}>{Traduccion('TXT_SU_VUELTO')}</Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>{FormatImporte(vuelto)}</Grid>
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <i>{Traduccion('TXT_GRACIAS_POR_SU_COMPRA')}</i>
                    </Grid>
                </Grid>
            </div>
            )
}