import { dateToJson } from '../utils/DateUtil';
import { GetCall, PostCall, PutCall } from './_serviceHelperApi'

const serviceUrl = '/usuarios';

/**
 * @returns obj devuelto: {errores, data}
 */
export const listarUsuarios = (filters) => {
    var params = [
        { 'key': 'activo', 'val': filters?.activo }
    ];
    return GetCall(serviceUrl, params)
}

export const autocompUsuario = (texto) => {
    return GetCall(serviceUrl + '/autocompletar', [{ 'key': 'texto', 'val': texto }]);
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const agregarUsuario = (unUsuario) => {
    return PostCall(serviceUrl, unUsuario)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const modificarUsuario = (unUsuario) => {
    return PutCall(serviceUrl, unUsuario)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const borrarUsuarios = (listaIds) => {
    return PutCall(serviceUrl + '/borrar', listaIds)
}

/**
 * @returns obj devuelto: {errores, data}
 */
export const reactivarUsuarios = (listaIds) => {
    return PutCall(serviceUrl + '/reactivar', listaIds)
}

/**
 * @returns lista de transacciones para el cliente seleccionado. Obj devuelto: {errores, data}
 */
export const listarTransacciones = (filters) => {
    var params = [
        { 'key': 'clienteId', 'val': filters?.clienteId },
        { 'key': 'fechaDesde', 'val': dateToJson(filters?.fechaDesde) },
        { 'key': 'fechaHasta', 'val': dateToJson(filters?.fechaHasta) },
        { 'key': 'pageNumber', 'val': filters?.pageNumber },
        { 'key': 'pageSize', 'val': filters?.pageSize }
    ];
    return GetCall(serviceUrl + '/transacciones', params)
}

/**
 * @param {objTransaccion} unaTransaccion {cliente, importe, tipoTransaccion}
 * @returns obj devuelto: {errores, data}
 */
export const agregarTransaccion = (unaTransaccion) => {
    return PostCall(serviceUrl + '/transacciones', unaTransaccion)
}