import React from 'react'
import { DataTable } from './DataTable'
import { PropTypes } from 'prop-types'
import { Tipos } from '../utils/Tipos'

export const DataTableProductosElegidos = ({data = [], component, handleDelete, isLoading}) => {
    
    const formatData = (d) => {
        return d.map(obj => {
            return {
                id: obj.id? obj.id : obj.producto.id,
                cant: obj.cantidad,
                prod: obj.producto.codigo + ' - ' + obj.producto.descripcion,
                prec: obj.precio,
                importe: obj.cantidad * obj.precio
            }
        })
    }
    
    return <DataTable title={'TXT_PRODUCTOS'}
                       headers={['TXT_CANTIDAD', 'TXT_PRODUCTO', 'TXT_PRECIO_UNITARIO', 'TXT_IMPORTE']}
                       types={[Tipos().CANT, Tipos().DESC, Tipos().IMP, Tipos().IMP]}
                       access={['cant', 'prod', 'prec', 'importe']}
                       rowId="id"
                       data={formatData(data)}
                       component={component}
                       hasDel={handleDelete ? true : false}
                       hasSee={false}
                       openDetail={handleDelete}
                       isLoading={isLoading}/>
}

DataTableProductosElegidos.propTypes = {
    data: PropTypes.array.isRequired,
    handleDelete: PropTypes.func
}