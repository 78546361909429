import { GetCall } from './_serviceHelperSeg'

const serviceUrl = '/tenant';

export const getTenantInfo = (tenantId) => {
    var params = [
        {'key': 't', 'val': tenantId}
    ];
    return GetCall(serviceUrl+'/info', params).then(d => {return {errores: d.errores, tenant: d.data}});;
};

export const getModules = () => {
    return GetCall(serviceUrl+'/modulos', []).then(d => {return {errores: d.errores, modulos: d.data}});;
}