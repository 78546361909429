import React from 'react';
import { AutocompleteComp } from './Autocomplete';
import { autocompProducto } from '../services/producto';

export const AutocompleteProducto = ({id, onSelect, clearOnSelect, autoFocus, seleccion, editable}) => {
    
    const itemLabel = (obj) => obj ? obj.codigo + ' - ' + obj.descripcion : '';

    return <AutocompleteComp id={id} serviceCall={autocompProducto} label="TXT_PRODUCTO"
                      itemLabel={itemLabel} onSelect={onSelect} clearOnSelect={clearOnSelect} autoFocus={autoFocus} seleccion={seleccion}
                      editable={editable}/>;
};