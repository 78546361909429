import { GetCall } from './_serviceHelperSeg2'

const serviceUrl = '/autenticacion';

export const getInfo = async (email) => {
    var params = [
        {key: 'email', val: email}
    ]
    const res = await GetCall(serviceUrl + '/info', params)
    res.tenants = res.data
    delete res.data
    return res
};

export const getLogos = async (email, tenantIds) => {
    var params = [
        {key: 'email', val: email}
    ]
    tenantIds.forEach(t => params.push({key: 't', val: t}))
    const res = await GetCall(serviceUrl + '/logos', params)
    res.tenants = res.data
    delete res.data
    return res
}

export const getToken = async (email, pass, tenantId) => {
    var params = [
        {'key': 't', 'val': tenantId},
        {'key': 'email', 'val': email},
        {'key': 'pass', 'val': pass}
    ]
    const res = await GetCall(serviceUrl, params)
    res.token = res.data
    delete res.data
    return res
}