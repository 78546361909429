import { getTenantInfo as getInfo } from '../services/tenant'

export const getTenantInfo = (tenantId) => {
    let aux = localStorage.getItem('tenant');
    return (typeof aux === "undefined" || !aux)  ? null : JSON.parse(aux).find(e => e.id === tenantId);
};

export const saveTenantInfo = (tenant) => {
    let aux = localStorage.getItem('tenant');
    let tenantList = aux ? JSON.parse(aux) : [];
    let elemIdx = tenantList.indexOf(tenantList.find(e => e.id === tenant.id));
    elemIdx < 0
        ? tenantList.push(tenant)
        : tenantList[elemIdx] = tenant;
    localStorage.setItem('tenant', JSON.stringify(tenantList));
};

export const updateTenantInfo = (tenant) => {
    let tGuardado = getTenantInfo(tenant.id);
    //!(tGuardado && tGuardado.razonSocial) &&
        getInfo(tenant.id).then(res => {
            let tBuscado = res.tenant;
            tBuscado.id = tenant.id;
            tBuscado.logo = tGuardado.logo;
            tBuscado.versionLogo = tGuardado.versionLogo;
            saveTenantInfo(tBuscado);
        });
};

export const getLastTenantUser = (user) => {
    let lastTenant = localStorage.getItem('lastTenant');
    lastTenant = lastTenant ? JSON.parse(lastTenant) : [];
    let elem = lastTenant.find(e => e.email === user.email);
    return elem ? elem.tenantId : null;
};

export const setLastTenantUser = (user, tenantId) => {
    let lastTenant = localStorage.getItem('lastTenant');
    lastTenant = lastTenant ? JSON.parse(lastTenant) : [];
    let elemIdx = lastTenant.indexOf(lastTenant.find(e => e.email === user.email));
    if (elemIdx < 0) {
        let usrTnt = {email: user.email, tenantId: tenantId};
        lastTenant.push(usrTnt);
    } else {
        lastTenant[elemIdx].tenantId = tenantId;
    }
    localStorage.setItem('lastTenant', JSON.stringify(lastTenant));
};