import React from 'react'
import { Grid } from '@mui/material'
import { InputTexto, InputCodigo } from '../../comp/Input'
import { InputSelect as SelectCategorias } from './categorias/InputSelect'
import { ModalSearch } from '../../comp/ModalSearch'
import { FiltroEstadoActivo } from '../../comp/FiltroEstadoActivo'
import { Traduccion } from '../../comp/Traduccion'

export const Filtros = ({filtros = {codigo: null, descripcion: '', categoriaId: null, estadoActivo: true}, 
    isOpen, onSubmit}) => {

    const [codigo, setCodigo] = React.useState(filtros.codigo);
    const [descripcion, setDescripcion] = React.useState(filtros.descripcion);
    const [categoriaId, setCategoriaId] = React.useState(filtros.categoriaId)
    const [estadoActivo, setEstadoActivo] = React.useState(filtros.estadoActivo);

    const modalSearchMaxWidth = 300;

    const _handleSubmit = (res) => {
        if (!res) {
            setCodigo(filtros.codigo);
            setDescripcion(filtros.descripcion);
            setCategoriaId(filtros.categoriaId);
            setEstadoActivo(filtros.estadoActivo);
        }
        onSubmit(res, {codigo: codigo, descripcion: descripcion, categoriaId: categoriaId, estadoActivo: estadoActivo});
    };

    React.useEffect(() => {
        setCodigo(filtros.codigo);
        setDescripcion(filtros.descripcion);
        setCategoriaId(filtros.categoriaId);
        setEstadoActivo(filtros.estadoActivo);
    }, [filtros]);

    return (<ModalSearch isOpen={isOpen} onSubmit={_handleSubmit}>
        <Grid container spacing={2} style={{maxWidth: modalSearchMaxWidth}}>
            <Grid item xs={12}>
                <InputCodigo id='codigo_producto'
                             label={Traduccion('TXT_CODIGO')}
                             value={codigo}
                             onChange={e => setCodigo(e)}
                             digits={18}
                             autoFocus/>
            </Grid>
            <Grid item xs={12}>
                <InputTexto id='descripcion_producto'
                            label={Traduccion('TXT_DESCRIPCION')}
                            value={descripcion}
                            onChange={e => setDescripcion(e)}/>
            </Grid>
            <Grid item xs={12}>
                <SelectCategorias id='categoria_producto'
                                  actualizar={isOpen}
                                  categoriaId={categoriaId} 
                                  onChange={v => setCategoriaId(v)}/>
            </Grid>
            <Grid item xs={12}>
                <FiltroEstadoActivo id='estado_activo_producto'
                                    value={estadoActivo}
                                    onChange={setEstadoActivo}/>
            </Grid>
        </Grid>
    </ModalSearch>);
}