import { getEncodedToken } from '../config/Sesion'

const getHeaderConfig = (method) => {
    return {
        method,
        headers: { 'X-Bearer': getEncodedToken() }
    }
}

const fetchCall = async (url, config) => {
    const response = await fetch(url, config)
    try {
        const res = await response.json()
        res.status = response.status
        return res
    } catch (err) {
        alert('Problema de conexión:\r\nrevisar conectividad a internet')
        console.error(err)
        return { status: 500, errores: ['ERR_INESPERADO'] }
    }
}

const getQueryParams = (filters) => {
    if (filters === undefined) {
        return ''
    }
    var filledParams = filters.filter(obj => obj.val !== null && obj.val !== undefined && obj.val !== '')
    return filledParams.length > 0
        ? '?' + filledParams.map(obj => obj.key + '=' + obj.val).join('&')
        : ''
}

export const GetCall = async (url, params) =>
    await fetchCall(url + getQueryParams(params), getHeaderConfig('GET'))

